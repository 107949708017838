import Vue from "vue";
import axios from "axios";
import Qs from "qs";
import md5 from "js-md5";
import config from "../config";
import { Message } from 'element-ui';
import router from '../router/index'
axios.defaults.baseURL = config.host;
const prefix = config.apiPrefix;

/**
 * 网络请求异常处理
 * @param {*} error
 */
const responseError = error => {
  if (error.response) {
    const response = error.response;
    Message.error(response.statusText);
  }
};

/**
 * 请求成功处理
 * @param {*} res
 * @param {*} resolve
 * @param {*} reject
 */
const responseSuccess = (response, resolve, reject) => {
  
  const data = response.data;
  if (data.code === 200) {
    resolve(data.data);
  } else if(data.code === 40100){
    router.replace(config.loginRoute)
  } else {
    // 授权失败跳转登录
    reject(data);
  }
};

/**
 * @desc 请求
 * @param {*} url
 * @param {*} query
 */
export const request = (url, query = {}, method = 'GET') => {
  const session = Vue.localStorage.get(md5("session"));
  if (session) {
    query.session = session;
  }
  let headers = {
    'Content-Type': "application/x-www-form-urlencoded"
  }
  let params = {
    url: prefix + url,
    method: method,
    headers: headers,
  }
  if (method === 'GET') {
    params.params = query;
  } else {
    params.data = Qs.stringify(query);
  }
  return new Promise((resolve, reject) => {
    axios(params)
      .then(res => responseSuccess(res, resolve, reject))
      .catch(error => responseError(error));
  });
};

/**
 * @desc 文件上传
 * @param {*} url
 * @param {*} query
 */
export const reqUpload = (url, query = {}) => {
  // const session = Vue.localStorage.get(md5("session"));
  // if (session) {
  //   query.session = session;
  // }
  let headers = {
    'Content-Type': "application/x-www-form-urlencoded"
  }
  let params = {
    url: prefix + url,
    method: 'POST',
    headers: headers,
    data: query,
  }
  return new Promise((resolve, reject) => {
    axios(params)
      .then(res => responseSuccess(res, resolve, reject))
      .catch(error => responseError(error));
  });
};