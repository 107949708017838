// 接口域名

const HOST = "/";
const apiPrefix = "/api";
const appName = '智慧运维平台';
// const appName = '智慧在线运维平台';

// MQTT
// const WS_HOST = 'ws://47.113.101.50:8083';
// const WS_HOST = 'wss://zhwit.fsenv.cn/';
const WS_HOST = 'wss://zhwit.fsenv.cn/';

const WS_PACKAGE = '/mqtt';

export default {
  // 域名
  host: HOST,
  // 接口前缀
  apiPrefix: apiPrefix,
  // 图片前缀
  storage: HOST,
  // 名称
  appName: appName,
  // 登录页面
  loginRoute: '/admin/login',
  // 首页
  homeRoute: '/admin/dashboard',
  // mqtt config
  mqttOption: {
    username: 'guester',
    password: 'hx123456'
  },
  // MQTT 服务器
  mqttConnect: WS_HOST + WS_PACKAGE,
};
