<template>
  <el-container class="app-frame">
    <el-aside class="app-aside" :width="collapse ? asideMin : asideMax">
      <app-menu></app-menu>
    </el-aside>
    <el-container class="app-frame-container">
      <el-header class="app-header">
        <div class="app-header-collapse" @click="toggleCollapse(!collapse)">
          <i :class="collapse ? 'el-icon-s-unfold': 'el-icon-s-fold'"></i>
        </div>
        <app-header></app-header>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import appMenu from "components/app-menu.vue";
import appHeader from "components/app-header.vue";
export default {
  components: {
    appMenu,
    appHeader,
  },
  data() {
    return {
      asideMax: '200px',
      asideMin: '64px',
    };
  },
  computed: mapState({
    collapse: (state) => state.collapse,
  }),
  methods: {
    ...mapActions(['toggleCollapse'])
  }
};
</script>

<style lang="less" scoped>
.app-frame {
  height: 100%;
  .app-aside {
    background-color: rgb(29, 37, 49);
  }
  .app-frame-container {
    .app-header {
      display: flex;
      align-items: center;
      padding-left: 0;
      background-color: #ffffff;
      box-shadow: 0 1px 4px 0 rgb(0 21 41 / 8%);
      .app-header-collapse {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 24px;
          color: #666666;
        }
      }
    }
  }
}
</style>
