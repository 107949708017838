<template>
  <div class="app-header">
    <div class="app-header-left">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="config.homeRoute == url ? '' : config.homeRoute">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i" :to="i == 1 ? url : ''">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="app-header-right">
      <!-- <div class="app-header-icon">
        <i class="el-icon-chat-dot-round"></i>
      </div> -->
      <el-tooltip class="item" effect="dark" content="清除缓存" placement="bottom">
        <div class="app-header-icon" @click="cleanCache">
          <i class="el-icon-refresh"></i>
        </div>
      </el-tooltip>

      <el-dropdown class="app-header-userinfo" @command="handleCommand">
        <span class="el-dropdown-link">
          <el-avatar v-if="user.avatar_url" :src="config.storage + user.avatar_url"></el-avatar>
          <span style="padding-left: 10px">{{ user.name }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="userCenter">个人中心</el-dropdown-item>
          <!-- <el-dropdown-item command="myNotice">我的消息</el-dropdown-item> -->
          <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: mapState({
    config: (state) => state.config,
    user: (state) => state.user,
    url: (state) => state.url,
    breadcrumb: (state) => state.breadcrumb,
  }),
  methods: {
    ...mapActions(["pageInit"]),
    handleCommand(item) {
      const _this = this;
      if (item === "logout") {
        _this
          .$confirm("是否退出当前登录?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "退出登录成功!",
            });
            _this.$localStorage.remove(md5("session"));
            _this.$localStorage.remove(md5("pageInit"));
            _this.$localStorage.remove(md5("menus"));
            _this.$router.push(_this.config.loginRoute);
          })
          .catch(() => {});
      }
      if (item === "userCenter") {
        this.$router.push('/admin/set/user')
      }
    },
    cleanCache() {
      const _this = this;
      _this.$localStorage.remove(md5("pageInit"));
      _this.$localStorage.remove(md5("menus"));
      window.top.location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.app-header {
  flex: 1;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .app-header-left {
    flex: 1;
  }
  .app-header-right {
    display: flex;
    align-items: center;
    .app-header-userinfo {
      .el-dropdown-link {
        display: flex;
        align-items: center;
      }
    }
    .app-header-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #666666;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
</style>