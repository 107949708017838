import config from '../config';
import AppFrame from 'components/app-frame.vue';

export default [
  {
    path: '/',
    redirect: config.homeRoute,
    component: AppFrame,
    children: [
      {
        path: config.homeRoute,
        meta: { title: '首页', breadcrumb: '' },
        component: () => import('views/dashboard/page.vue')
      },
      {
        path: '/admin/monitor/outlet',
        meta: { title: '排口监测', breadcrumb: '在线监测/排口监测' },
        component: () => import('views/monitor/outlet/page.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/real/:id',
        meta: { title: '实时数据', breadcrumb: '在线监测/排口监测/实时数据' },
        component: () => import('views/monitor/outlet/detail/real.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/history/:id',
        meta: { title: '历史数据', breadcrumb: '在线监测/排口监测/历史数据' },
        component: () => import('views/monitor/outlet/detail/history.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/curve/:id',
        meta: { title: '数据曲线', breadcrumb: '在线监测/排口监测/数据曲线' },
        component: () => import('views/monitor/outlet/detail/curve.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/dataup/:id',
        meta: { title: '数据上传率', breadcrumb: '在线监测/排口监测/数据上传率' },
        component: () => import('views/monitor/outlet/detail/dataup.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/historyrealdata/:id',
        meta: { title: '历史实时数据', breadcrumb: '在线监测/排口监测/历史实时数据' },
        component: () => import('views/monitor/outlet/detail/historyrealdata.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/:id',
        meta: { title: '数采操作', breadcrumb: '在线监测/排口监测/数采操作' },
        component: () => import('views/monitor/outlet/detail/control.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/record/:id',
        meta: { title: '停运记录', breadcrumb: '在线监测/排口监测/停运记录' },
        component: () => import('views/monitor/outlet/detail/record.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/setrepeatnum/:id',
        meta: { title: '设置超时时间及重发次数', breadcrumb: '在线监测/排口监测/数采操作/设置超时时间及重发次数' },
        component: () => import('views/monitor/outlet/detail/control/setrepeatnum.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/setinterval/:id',
        meta: { title: '设置分钟时间间隔', breadcrumb: '在线监测/排口监测/数采操作/设置分钟时间间隔' },
        component: () => import('views/monitor/outlet/detail/control/setinterval.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/senddata/:id',
        meta: { title: '历史数据补传', breadcrumb: '在线监测/排口监测/数采操作/历史数据补传' },
        component: () => import('views/monitor/outlet/detail/control/senddata.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/setrealtime/:id',
        meta: { title: '设置实时数据间隔', breadcrumb: '在线监测/排口监测/数采操作/设置实时数据间隔' },
        component: () => import('views/monitor/outlet/detail/control/setrealtime.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/pollutionSet/:id',
        meta: { title: '污染物实时数据操作', breadcrumb: '在线监测/排口监测/数采操作/污染物实时数据操作' },
        component: () => import('views/monitor/outlet/detail/control/pollutionSet.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/overproofSample/:id',
        meta: { title: '超标留样', breadcrumb: '在线监测/排口监测/数采操作/超标留样' },
        component: () => import('views/monitor/outlet/detail/control/overproofSample.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/settime/:id',
        meta: { title: '现场机时间设置', breadcrumb: '在线监测/排口监测/数采操作/现场机时间设置' },
        component: () => import('views/monitor/outlet/detail/control/settime.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/zeroCalibration/:id',
        meta: { title: '零点校准量程校准', breadcrumb: '在线监测/排口监测/数采操作/零点校准量程校准' },
        component: () => import('views/monitor/outlet/detail/control/zeroCalibration.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/instantlySampling/:id',
        meta: { title: '即时采样', breadcrumb: '在线监测/排口监测/数采操作/即时采样' },
        component: () => import('views/monitor/outlet/detail/control/instantlySampling.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/cleanBlowback/:id',
        meta: { title: '启动清洗/反吹', breadcrumb: '在线监测/排口监测/数采操作/启动清洗/反吹' },
        component: () => import('views/monitor/outlet/detail/control/cleanBlowback.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/equipSampling/:id',
        meta: { title: '比对采样', breadcrumb: '在线监测/排口监测/数采操作/比对采样' },
        component: () => import('views/monitor/outlet/detail/control/equipSampling.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/samplingPeriodSet/:id',
        meta: { title: '设置采样时间周期', breadcrumb: '在线监测/排口监测/数采操作/设置采样时间周期' },
        component: () => import('views/monitor/outlet/detail/control/samplingPeriodSet.vue')
      },
      {
        path: '/admin/monitor/outlet-detail/control/equipInformationGet/:id',
        meta: { title: '提取仪器信息', breadcrumb: '在线监测/排口监测/数采操作/提取仪器信息' },
        component: () => import('views/monitor/outlet/detail/control/equipInformationGet.vue')
      },
      {
        path: '/admin/monitor/gis',
        meta: { title: 'GIS监测', breadcrumb: '在线监测/GIS监测' },
        component: () => import('views/monitor/gis/page.vue')
      },
      {
        path: '/admin/early/log',
        meta: { title: '预警信息', breadcrumb: '预警管理/预警信息' },
        component: () => import('views/early/log/page.vue')
      },
      {
        path: '/admin/early/log-history/:id',
        meta: { title: '历史记录', breadcrumb: '预警管理/预警信息/历史记录' },
        component: () => import('views/early/log/detail/history.vue')
      },
      {
        path: '/admin/early/log-line/:id',
        meta: { title: '历史曲线', breadcrumb: '预警管理/预警信息/历史曲线' },
        component: () => import('views/early/log/detail/line.vue')
      },
      {
        path: '/admin/early/confirm',
        meta: { title: '确认记录', breadcrumb: '预警管理/确认记录' },
        component: () => import('views/early/confirm/page.vue')
      },
      {
        path: '/admin/early/handle',
        meta: { title: '处理记录', breadcrumb: '预警管理/处理记录' },
        component: () => import('views/early/handle/page.vue')
      },
      {
        path: '/admin/early/handle-detail/table/:id',
        meta: { title: '台账详情', breadcrumb: '预警管理/处理记录/台账详情' },
        component: () => import('views/maintain/signin/detail/page.vue')
      },
      {
        path: '/admin/maintain/signin',
        meta: { title: '签到记录', breadcrumb: '运维管理/签到记录' },
        component: () => import('views/maintain/signin/page.vue')
      },
      {
        path: '/admin/maintain/signin-detail/:id',
        meta: { title: '记录详情', breadcrumb: '运维管理/签到记录/记录详情' },
        component: () => import('views/maintain/signin/detail/page.vue')
      },
      {
        path: '/admin/maintain/formScreen',
        meta: { title: '台账筛选', breadcrumb: '运维管理/台账筛选' },
        component: () => import('views/maintain/formScreen/page.vue')
      },
      {
        path: '/admin/maintain/formScreen-detail/:id',
        meta: { title: '记录详情', breadcrumb: '运维管理/台账筛选/记录详情' },
        component: () => import('views/maintain/formScreen/detail/page.vue')
      },
      {
        path: '/admin/maintain/reagent',
        meta: { title: '试剂记录', breadcrumb: '运维管理/试剂记录' },
        component: () => import('views/maintain/reagent/page.vue')
      },
      {
        path: '/admin/maintain/reagent-detail/:id',
        meta: { title: '记录详情', breadcrumb: '运维管理/试剂记录/记录详情' },
        component: () => import('views/maintain/reagent/detail/page.vue')
      },
      {
        path: '/admin/maintain/early',
        meta: { title: '故障处理记录', breadcrumb: '运维管理/故障处理记录' },
        component: () => import('views/maintain/early/page.vue')
      },
      {
        path: '/admin/maintain/early-detail/:id',
        meta: { title: '记录详情', breadcrumb: '运维管理/故障处理记录/记录详情' },
        component: () => import('views/maintain/early/detail/page.vue')
      },
      {
        path: '/admin/maintain/early-detail/table/:id',
        meta: { title: '台账详情', breadcrumb: '运维管理/故障处理记录/台账详情' },
        component: () => import('views/maintain/signin/detail/page.vue')
      },
      // {
      //   path: '/admin/construction/list',
      //   meta: { title: '项目建设列表', breadcrumb: '项目建设/项目建设列表' },
      //   component: () => import('views/construction/list/page.vue')
      // },
      // {
      //   path: '/admin/construction/list-add',
      //   meta: { title: '新建项目', breadcrumb: '项目建设/项目建设列表/新建项目' },
      //   component: () => import('views/construction/list/add/page.vue')
      // },
      // {
      //   path: '/admin/construction/list-detail/:id',
      //   meta: { title: '项目详情', breadcrumb: '项目建设/项目建设列表/项目详情' },
      //   component: () => import('views/construction/list/detail/page.vue')
      // },
      {
        path: '/admin/basic/enterprise',
        meta: { title: '企业数据', breadcrumb: '数据录入/企业数据' },
        component: () => import('views/basic/enterprise/page.vue')
      },
      {
        path: '/admin/basic/enterprise-add',
        meta: { title: '企业添加', breadcrumb: '数据录入/企业数据/企业添加' },
        component: () => import('views/basic/enterprise/add/page.vue')
      },
      {
        path: '/admin/basic/enterprise-detail/:id',
        meta: { title: '企业详情', breadcrumb: '数据录入/企业数据/企业详情' },
        component: () => import('views/basic/enterprise/detail/page.vue')
      },
      {
        path: '/admin/basic/equipment',
        meta: { title: '排口数据', breadcrumb: '数据录入/排口数据' },
        component: () => import('views/basic/equipment/page.vue')
      },
      {
        path: '/admin/basic/equipment-add',
        meta: { title: '排口添加', breadcrumb: '数据录入/排口数据/排口添加' },
        component: () => import('views/basic/equipment/add/page.vue')
      },
      {
        path: '/admin/basic/equipment-detail/detail/:id',
        meta: { title: '排口详情', breadcrumb: '数据录入/排口数据/排口详情' },
        component: () => import('views/basic/equipment/detail/detail/page.vue')
      },
      {
        path: '/admin/basic/equipment-detail/factor/:id',
        meta: { title: '因子管理', breadcrumb: '数据录入/排口数据/因子管理' },
        component: () => import('views/basic/equipment/detail/factor/page.vue')
      },
      {
        path: '/admin/basic/equipment-detail/device/:id',
        meta: { title: '仪器管理', breadcrumb: '数据录入/排口数据/仪器管理' },
        component: () => import('views/basic/equipment/detail/device/page.vue')
      },
      {
        path: '/admin/basic/template',
        meta: { title: '故障模板', breadcrumb: '数据录入/故障模板' },
        component: () => import('views/basic/template/page.vue')
      },
      {
        path: '/admin/basic/template-add',
        meta: { title: '模板添加', breadcrumb: '数据录入/故障模板/模板添加' },
        component: () => import('views/basic/template/add/page.vue')
      },
      {
        path: '/admin/basic/template-detail/:id',
        meta: { title: '模板详情', breadcrumb: '数据录入/故障模板/模板详情' },
        component: () => import('views/basic/template/detail/page.vue')
      },
      {
        path: '/admin/basic/sim',
        meta: { title: '物联卡管理', breadcrumb: '数据录入/物联卡管理' },
        component: () => import('views/basic/sim/page.vue')
      },
      {
        path: '/admin/basic/sim-add',
        meta: { title: '物联卡管理', breadcrumb: '数据录入/物联卡管理/添加物联卡' },
        component: () => import('views/basic/sim/add/page.vue')
      },
      {
        path: '/admin/basic/sim-detail/:id',
        meta: { title: '物联卡管理', breadcrumb: '数据录入/物联卡管理/物联卡详情' },
        component: () => import('views/basic/sim/detail/page.vue')
      },
      {
        path: '/admin/basic/simlog',
        meta: { title: '物联卡更换记录', breadcrumb: '数据录入/物联卡更换记录' },
        component: () => import('views/basic/simlog/page.vue')
      },
      {
        path: '/admin/personnel/staff',
        meta: { title: '内部人员', breadcrumb: '人员管理/内部人员' },
        component: () => import('views/personnel/staff/page.vue')
      },
      {
        path: '/admin/personnel/staff-add',
        meta: { title: '人员添加', breadcrumb: '人员管理/内部人员/人员添加' },
        component: () => import('views/personnel/staff/add/page.vue')
      },
      {
        path: '/admin/personnel/staff-detail/:id',
        meta: { title: '人员详情', breadcrumb: '人员管理/内部人员/人员详情' },
        component: () => import('views/personnel/staff/detail/page.vue')
      },
      {
        path: '/admin/personnel/custom',
        meta: { title: '企业人员', breadcrumb: '人员管理/企业人员' },
        component: () => import('views/personnel/custom/page.vue')
      },
      {
        path: '/admin/personnel/custom-add',
        meta: { title: '添加企业人员', breadcrumb: '人员管理/企业人员/添加企业人员' },
        component: () => import('views/personnel/custom/add/page.vue')
      },
      {
        path: '/admin/personnel/custom-detail/:id',
        meta: { title: '企业人员详情', breadcrumb: '人员管理/企业人员/企业人员详情' },
        component: () => import('views/personnel/custom/detail/page.vue')
      },
      // {
      //   path: '/admin/memo/list',
      //   meta: { title: '备忘录列表', breadcrumb: '备忘录/备忘录列表' },
      //   component: () => import('views/memo/list/page.vue')
      // },
      // {
      //   path: '/admin/management/list',
      //   meta: { title: '资料列表', breadcrumb: '资料管理/资料列表' },
      //   component: () => import('views/management/list/page.vue')
      // },
      // {
      //   path: '/admin/management/list-add',
      //   meta: { title: '添加资料', breadcrumb: '资料管理/资料列表/添加资料' },
      //   component: () => import('views/management/list/add/page.vue')
      // },
      // {
      //   path: '/admin/management/list-detail/:id',
      //   meta: { title: '资料详情', breadcrumb: '资料管理/资料列表/资料详情' },
      //   component: () => import('views/management/list/detail/page.vue')
      // },
      // {
      //   path: '/admin/management/classifyList',
      //   meta: { title: '资料分类', breadcrumb: '资料管理/资料分类' },
      //   component: () => import('views/management/classifyList/page.vue')
      // },
      // {
      //   path: '/admin/management/classifyList-add',
      //   meta: { title: '资料添加', breadcrumb: '资料管理/资料分类/资料添加' },
      //   component: () => import('views/management/classifyList/add/page.vue')
      // },
      {
        path: '/admin/evaluate/list',
        meta: { title: '统计数据', breadcrumb: '运维统计/统计数据' },
        component: () => import('views/evaluate/list/page.vue')
      },
      {
        path: '/admin/push/error',
        meta: { title: '故障推送', breadcrumb: '推送管理/故障推送' },
        component: () => import('views/push/error/page.vue')
      },
      {
        path: '/admin/push/handle',
        meta: { title: '处理推送', breadcrumb: '推送管理/处理推送' },
        component: () => import('views/push/handle/page.vue')
      },
      {
        path: '/admin/set/permission',
        meta: { title: '权限配置', breadcrumb: '系统设置/权限配置' },
        component: () => import('views/setting/permission/page.vue')
      },
      {
        path: '/admin/set/template',
        meta: { title: '模板配置', breadcrumb: '系统设置/模板配置' },
        component: () => import('views/setting/template/page.vue')
      },
      // {
      //   path: '/admin/set/sms',
      //   meta: { title: '短信配置', breadcrumb: '系统设置/短信配置' },
      //   component: () => import('views/setting/sms/page.vue')
      // },
      {
        path: '/admin/set/system',
        meta: { title: '系统信息', breadcrumb: '系统设置/系统信息' },
        component: () => import('views/setting/system/page.vue')
      },
      {
        path: '/admin/set/log',
        meta: { title: '操作日志', breadcrumb: '系统设置/操作日志' },
        component: () => import('views/setting/log/page.vue')
      },
      {
        path: '/admin/set/versions',
        meta: { title: '操作日志', breadcrumb: '系统设置/版本日志' },
        component: () => import('views/setting/versions/page.vue')
      },
      {
        path: '/admin/set/versions/create',
        meta: { title: '操作日志', breadcrumb: '系统设置/添加版本日志' },
        component: () => import('views/setting/versions/create.vue')
      },
      {
        path: '/admin/set/user',
        meta: { title: '个人中心', breadcrumb: '系统设置/个人中心' },
        component: () => import('views/setting/user/page.vue')
      },
    ]
  },
  {
    path: config.loginRoute,
    meta: { title: '后台登录', breadcrumb: '' },
    component: () => import('../views/login/page.vue')
  },
  {
    path: '*',
    component: () => import('../views/error/404/page.vue')
  }
]