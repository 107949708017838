import Vue from 'vue';
import Vuex from 'vuex';
import md5 from 'js-md5';
import { Message } from 'element-ui';
import config from "src/config";
import { apiAccountUserInfo, apiAccountUserMenu } from 'src/api/index';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: config,
    collapse: false,
    user: {},
    permission: {},
    menus: [],
    url: '/',
    breadcrumb: [],
    res: null,
    status: 0,
    nowTime: 0
  },
  mutations: {
    changeCollapse(state, collapse) {
      state.collapse = collapse;
    },
    initUserInfo(state, user) {
      state.user = user;
    },
    initPermission(state, permission) {
      state.permission = permission;
    },
    initMenus(state, menus) {
      state.menus = menus;
    },
    initBreadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    // 更新url
    updateUri(state, url) {
      state.url = url.indexOf('-') === -1 ? url : url.split('-')[0];
    },
    saveData(state, data) {
      state.res = data;
    },
    saveStatus(state, status) {
      state.status = status;
    },
    // 获取当前时间，拼接成串
    getNowTime(state) {
      let time = parseInt(new Date().getTime() / 1000);
      let mili = new Date(time)
      var date = new Date(time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear();
      var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      var ss = mili.getMilliseconds() < 10 ? '00' + mili.getMilliseconds() : mili.getMilliseconds() < 100 ? '0' + mili.getMilliseconds() : mili.getMilliseconds();
      state.nowTime = Y + M + D + h + m + s + ss;
    }
  },
  actions: {
    toggleCollapse(context, result) {
      context.commit('changeCollapse', result)
    },
    // 页面初始化
    pageInit(context, _this) {
      // 加载本地用户缓存信息
      return new Promise(resolve => {
        const localPageInit = _this.$localStorage.get(md5('pageInit'));
        if (localPageInit) {
          const pageInitInfo = JSON.parse(localPageInit);
          context.commit('initUserInfo', pageInitInfo.user);
          context.commit('initPermission', pageInitInfo.permission);
          context.dispatch('menuInit', _this);
          resolve(pageInitInfo.user);
        } else {
          const loading = _this.$loading({
            lock: true,
            text: "页面加载中",
            background: "rgba(255, 255, 255, 0.4)"
          });
          apiAccountUserInfo().then(res => {
            loading.close();
            _this.$localStorage.set(md5("pageInit"), JSON.stringify(res));
            context.commit('initUserInfo', res.user);
            context.commit('initPermission', res.permission);
            context.dispatch('menuInit', _this,);
            resolve(res.user);
          }).catch((err) => {
            loading.close();
            if (err.code === 40100) {
              _this.$localStorage.remove(md5("session"));
            }
            _this.$localStorage.remove(md5("pageInit"));
            _this.$localStorage.remove(md5("menus"));
            _this.$router.push(config.loginRoute);
          })
        }
      });
    },
    // 导航菜单初始化
    menuInit(context, _this, loading) {
      const localMenus = _this.$localStorage.get(md5('menus'));
      if (localMenus) {
        const menus = JSON.parse(localMenus);
        context.commit('initMenus', menus);
      } else {
        apiAccountUserMenu().then(menus => {
          console.log(menus);
          _this.$localStorage.set(md5("menus"), JSON.stringify(menus));
          context.commit('initMenus', menus);
        }).catch(() => {})
      }
    },
    //数采操作响应
    equipResponse(context, response) {
      if (response.code == 1001) {
        return
      } else if (response.code == 2000) {
        context.commit('saveData', response);
      } else if (this.state.status == 1 && response.code == 2001) {
        context.commit('saveStatus', 0);
        Message({
          type: 'success',
          message: '保存成功'
        })
      }
      //  else {
      //   Message({
      //     type: 'warning',
      //     message: '没有数据'
      //   })
      // }
    },
  },
  modules: {
  }
})
