import Vue from 'vue';
import VueLocalStorage from "vue-localstorage";
import App from './components/app.vue';
import router from './router/index';
import store from './store/index';
import Print from 'vue-print-nb';
import config from "./config";
import mqtt from "mqtt";


import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend
} from 'vee-validate';
import zh from 'vee-validate/dist/locale/zh_CN.json';
import * as rules from "vee-validate/dist/rules";

import Element from 'element-ui';
import './assets/element-ui.css';
import './assets/app.css';

Vue.use(Print);
Vue.use(VueLocalStorage);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const getMessage = field => `${field}不能含有中文`;

const validate = value => {
  const regex = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
  return !regex.exec(value);
};

const Chinese = {
  validate,
  message: getMessage
};

extend("Chinese", Chinese);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
})

localize({ zh });
localize('zh');

Vue.use(Element, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false

// mqtt
var timestamp = Date.parse(new Date());
const ms = new Date().getMilliseconds();
const options = {
  clientId: "APPWEB" + timestamp + "" + ms,
  clean: true, // true: 清除会话, false: 保留会话
  password: config.mqttOption.password,
  username: config.mqttOption.username,
  keepalive: 10,
  reconnectPeriod: 1000,
  connectTimeout: 4 * 1000,
  resubscribe: true
};
const client = mqtt.connect(config.mqttConnect, options);
//建立连接后订阅主题
client.on("connect", function () {
  console.log("connect success");
  // 订阅一个主题
  let topic = 'root/guester/adminreceive';
  client.subscribe(topic);
});
// 后台数据接收
client.on("message", (topic, message) => {
  const content = JSON.parse(message.toString());
  store.dispatch("equipResponse", content);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
