import { request, reqUpload } from 'src/util/request';

// 账号登录
export const apiAccountLogin = (params) => request('/account/login', params, 'POST');
// 获取登录账号信息
export const apiAccountUserInfo = () => request('/account/userInfo', {}, 'GET');
// 获取登录账号导航菜单信息
export const apiAccountUserMenu = () => request('/account/userMenu', {}, 'GET');
//修改账号信息
export const apiAccountUserInfoUpdate = (params) => request('/account/userInfoUpdate', params, 'POST');
//用户头像修改
export const apiAccountUserAvatarUpdate = (params) => reqUpload('/account/userAvatarUpdate', params, 'POST');
//获取运维证书配置
export const apiPapersPapersList = (params) => request('/papers/papersList', params, 'GET');
//运维证书配置添加
export const apiPapersPapersAdd = (params) => request('/papers/papersAdd', params, 'POST');
//运维证书配置修改
export const apiPapersPapersUpdate = (params) => request('/papers/papersUpdate', params, 'POST');

// 权限配置
export const apiPermissionRoleList = () => request('/permission/roleList', {}, 'GET');
export const apiPermissionRolePermissionUpdate = (params) => request('/permission/rolePermissionUpdate', params, 'POST');

// 系统信息
export const apiSystemBasicInfo = () => request('/system/basicInfo', {}, 'GET');
export const apiSystemBasicInfoUpdate = (params) => request('/system/basicInfoUpdate', params, 'POST');

//获取人员列表信息
export const apiStaffUserList = (params) => request('/staff/userList', params, 'GET');
//添加人员信息
export const apiStaffUserCreate = (params) => request('/staff/userCreate', params, 'POST');
//获取人员信息
export const apiStaffUserDetail = (params) => request('/staff/userDetail', params, 'GET');
//修改人员信息
export const apiStaffUserUpdate = (params) => request('/staff/userUpdate', params, 'POST');
//获取人员职位信息
export const apiStaffUserRoleList = () => request('/staff/userRoleList', {}, 'GET');
//注销内部人员信息
export const apiStaffUserDelete = (params) => request('/staff/userDelete', params, 'POST');
//获取运维证书配置信息
export const apiStaffUserPapers = (params) => request('/staff/userPapers', params, 'GET');
//运维证书配置添加
export const apiStaffUserPapersAdd = (params) => request('/staff/userPapersAdd', params, 'POST');
//运维证书配置修改
export const apiStaffUserPapersUpdate = (params) => request('/staff/userPapersUpdate', params, 'POST');
//用户头像修改
export const apiStaffImgUpdate = (params) => reqUpload('/staff/imgUpdate', params, 'POST');

//获取客户企业列表
export const apiCustomEnterpriseList = (params) => request('/custom/enterpriseList', params, 'GET');
//查询企业用户列表
export const apiCustomUserList = (params) => request('/custom/userList', params, 'GET');
//添加客户信息
export const apiCustomUserCreate = (params) => request('/custom/userCreate', params, 'POST');
//查看客户详情信息
export const apiCustomUserDetail = (params) => request('/custom/userDetail', params, 'POST');
//修改人员信息
export const apiCustomUserUpdate = (params) => request('/custom/userUpdate', params, 'POST');
//注销客户
export const apiCustomUserDelete = (params) => request('/custom/userDelete', params, 'POST');
//更新客户账号有效期
export const apiCustomUserExpireUpdate = (params) => request('/custom/userExpireUpdate', params, 'POST');
//获取企业排口
export const apiCustomOutlet = (params) => request('/custom/outlet', params, 'GET');
//配置客户排口
export const apiCustomOutletChange = (params) => request('/custom/outletChange', params, 'POST');

//查询企业列表数据
export const apiEnterpriseList = (params) => request('/enterprise/list', params, 'GET');
//删除企业信息
export const apiEnterpriseDelete = (params) => request('/enterprise/delete', params, 'POST');
//查看企业详细信息
export const apiEnterpriseDetail = (params) => request('/enterprise/detail', params, 'GET');
//修改企业信息
export const apiEnterpriseUpdate = (params) => request('/enterprise/update', params, 'POST');
//添加企业信息
export const apiEnterpriseCreate = (params) => request('/enterprise/create', params, 'POST');
//修改企业等级
export const apiEnterpriseLevel = (params) => request('/enterprise/level', params, 'POST');

//查看排口列表信息
export const apiOutletEquipmentList = (params) => request('/outlet/equipmentList', params, 'GET');
//获取排口其他信息
export const apiOutletCondition = () => request('/outlet/condition', {}, 'GET');
//获取排口可选企业信息
export const apiOutletEnterpriseList = (params) => request('/outlet/enterpriseList', params, 'GET');
//删除排口
export const apiOutletEquipmentDelete = (params) => request('/outlet/equipmentDelete', params, 'POST');
//添加排口
export const apiOutletEquipmentCreate = (params) => request('/outlet/equipmentCreate', params, 'POST');
//查看排口详细信息
export const apiOutletEquipmentDetail = (params) => request('/outlet/equipmentDetail', params, 'GET');
//修改客户信息
export const apiOutletEquipmentUpdate = (params) => request('/outlet/equipmentUpdate', params, 'POST');
//更新排口联网信息
export const apiOutletEquipmentConnectUpdate = (params) => request('/outlet/equipmentConnectUpdate', params, 'POST');
//停运排口
export const apiOutletStop = (params) => request('/outlet/stop', params, 'POST');
//地图查询
export const apiRegionSearch = (params) => request('/region/search', params, 'GET');

//获取排口配置因子列表
export const apiFactorMonitorList = (params) => request('/factor/monitorList', params, 'GET');
//移除监测因子
export const apiFactorMonitorDelete = (params) => request('/factor/monitorDelete', params, 'POST');
//获取排口配置因子信息
export const apiFactorMonitorDetail = (params) => request('/factor/monitorDetail', params, 'GET');
//获取可选因子配置
export const apiFactorSelectList = (params) => request('/factor/selectList', params, 'GET');
//添加配置因子
export const apiFactorMonitorCreate = (params) => request('/factor/monitorCreate', params, 'POST');
//修改配置因子信息
export const apiFactorMonitorUpdate = (params) => request('/factor/monitorUpdate', params, 'POST');
//更新因子折算状态
export const apiFactorMonitorZsUpdate = (params) => request('/factor/monitorZsUpdate', params, 'POST');

//获取排口仪器列表
export const apiDeviceMonitorList = (params) => request('/device/monitorList', params, 'GET');
//获取排口仪器详情
export const apiDeviceMonitorDetail = (params) => request('/device/monitorDetail', params, 'GET');
//获取排口仪器信息
export const apiDeviceSelectList = (params) => request('/device/selectList', params, 'GET');
//删除监测仪器
export const apiDeviceMonitorDelete = (params) => request('/device/monitorDelete', params, 'POST');
//添加监测仪器
export const apiDeviceMonitorCreate = (params) => request('/device/monitorCreate', params, 'POST');
//修改监测仪器信息
export const apiDeviceMonitorUpdate = (params) => request('/device/monitorUpdate', params, 'POST');

//获取故障模板监测类型
export const apiFaultTemplateMonitor = () => request('/faultTemplate/monitor', {}, 'GET');
//获取故障模板列表
export const apiFaultTemplateList = (params) => request('/faultTemplate/list', params, 'GET');
//获取故障模板详情
export const apiFaultTemplateDetail = (params) => request('/faultTemplate/detail', params, 'GET');
//删除故障模板
export const apiFaultTemplateDelete = (params) => request('/faultTemplate/delete', params, 'POST');
//添加故障模板
export const apiFaultTemplateCreate = (params) => request('/faultTemplate/create', params, 'POST');
//修改故障模板
export const apiFaultTemplateUpdate = (params) => request('/faultTemplate/update', params, 'POST');

//获取信息统计
export const apiDashboardStatistic = () => request('/dashboard/statistic', {}, 'GET');
//获取人员信息统计
export const apiDashboardUserStatistic = () => request('/dashboard/userStatistic', {}, 'GET');
//获取表单提交总趋势
export const apiDashboardFormLine = (params) => request('/dashboard/formLine', params, 'GET');
//获取表单提交率类型
export const apiDashboardFormBarType = () => request('/dashboard/formBarType', {}, 'GET');
//获取表单提交率
export const apiDashboardFormBar = (params) => request('/dashboard/formBar', params, 'GET');

//获取运维人员排口签到记录
export const apiInspectionSignInList = (params) => request('/inspection/signInList', params, 'GET');
//获取排口签到台账表格记录
export const apiInspectionTableInfo = (params) => request('/inspection/tableInfo', params, 'GET');
//排口签到详情情况
export const apiInspectionSignInDetail = (params) => request('/inspection/signInDetail', params, 'GET');
//排口签到台账类型
export const apiInspectionFormType = (params) => request('/inspection/formType', params, 'GET');
//排口签到图片信息
export const apiInspectionPhotos = (params) => request('/inspection/photos', params, 'GET');
//排口签到视频信息
export const apiInspectionVideo = (params) => request('/inspection/video', params, 'GET');
//排口签到表格修改
export const apiInspectionTableUpdate = (params) => request('/inspection/tableUpdate', params, 'POST');

//排口监测列表
export const apiMonitorMonitorList = (params) => request('/Monitor/monitorList', params, 'GET');
//实时数据
export const apiMonitorMonitorReal = (params) => request('/Monitor/monitorReal', params, 'GET');
//24小时曲线
export const apiMonitorMonitorHourscurve = (params) => request('/Monitor//monitorHourCurve', params, 'GET');
//历史数据
export const apiMonitorMonitorHistory = (params) => request('/monitorHistory/dataList', params, 'GET');
//数据曲线
export const apiMonitorMonitorCurve = (params) => request('/monitorHistory/dataLine', params, 'GET');
//排口监测数据上次率列表
export const apiMonitorRateList = (params) => request('/monitorRate/list', params, 'GET');
//导出历史数据
export const apiMonitorHistoryExport = (params) => request('/monitorHistory/export', params, 'GET');
//导出年、月、日数据
export const apiMonitorHistoryExportHistory = (params) => request('/monitorHistory/exportHistory', params, 'GET');
//排口历史实时数据
export const apiMonitorHistoryDataReal = (params) => request('/monitorHistory/dataReal', params, 'GET');
//数采设备
export const apiDataOperationDevice = (params) => request('/dataOperation/device', params, 'GET');
//停运记录
export const apiMonitorStopRecord = (params) => request('/Monitor/stopRecord', params, 'GET');

//数采操作
//设置超时时间及重发次数
export const apiEquipControlsEquipRepeatNum = (params) => request('/equipControls/equipRepeatNum', params, 'GET');
//设置分钟时间间隔
export const apiEquipControlsDataMinuteIntervalGet = (params) => request('/equipControls/dataMinuteIntervalGet', params, 'GET');
export const apiEquipControlsDataMinuteIntervalSet = (params) => request('/equipControls/dataMinuteIntervalSet', params, 'GET');
//历史数据补传
export const apiEquipControlsDataMinuteGet = (params) => request('/equipControls/dataMinuteGet', params, 'GET');
export const apiEquipControlsDataHourGet = (params) => request('/equipControls/dataHourGet', params, 'GET');
export const apiEquipControlsDataDayGet = (params) => request('/equipControls/dataDayGet', params, 'GET');
//设备运行时间日历史数据
export const apiEquipControlsDataDayHistoryGet = (params) => request('/equipControls/dataDayHistoryGet', params, 'GET');
//设置实时数据间隔
export const apiEquipControlsDataRealIntervalGet = (params) => request('/equipControls/dataRealIntervalGet', params, 'GET');
export const apiEquipControlsDataRealIntervalSet = (params) => request('/equipControls/dataRealIntervalSet', params, 'GET');
//污染物实时数据操作
export const apiEquipControlsDataRealGet = (params) => request('/equipControls/dataRealGet', params, 'GET');
export const apiEquipControlsDataRealStop = (params) => request('/equipControls/dataRealStop', params, 'GET');
//设置设备运行状态
export const apiEquipControlsEquipRunStatusGet = (params) => request('/equipControls/equipRunStatusGet', params, 'GET');
export const apiEquipControlsEquipRunStatusStop = (params) => request('/equipControls/equipRunStatusStop', params, 'GET');
//超标留样
export const apiEquipControlsEquipOver = (params) => request('/equipControls/equipOver', params, 'GET');
//设备操作
//现场机时间设置
export const apiEquipControlsEquipTimeGet = (params) => request('/equipControls/equipTimeGet', params, 'GET');
export const apiEquipControlsEquipTimeSet = (params) => request('/equipControls/equipTimeSet', params, 'GET');
//零点校准量程校准
export const apiEquipControlsEquipCorrect = (params) => request('/equipControls/equipCorrect', params, 'GET');
//即时采样
export const apiEquipControlsEquipSampling = (params) => request('/equipControls/equipSampling', params, 'GET');
//启动清洗/反吹
export const apiEquipControlsEquipClean = (params) => request('/equipControls/equipClean', params, 'GET');
//比对采样
export const apiEquipControlsEquipContrast = (params) => request('/equipControls/equipContrast', params, 'GET');
//设置采样时间周期
export const apiEquipControlsEquipSamplingPeriodSet = (params) => request('/equipControls/equipSamplingPeriodSet', params, 'GET');
export const apiEquipControlsEquipSamplingPeriodGet = (params) => request('/equipControls/equipSamplingPeriodGet', params, 'GET');
//提取仪器信息
export const apiEquipControlsEquipSamplingTimeGet = (params) => request('/equipControls/equipSamplingTimeGet', params, 'GET');
export const apiEquipControlsEquipUniqueGet = (params) => request('/equipControls/equipUniqueGet', params, 'GET');
export const apiEquipControlsEquipInfoGet = (params) => request('/equipControls/equipInfoGet', params, 'GET');

//获取试剂申请记录
export const apiReagentApplyList = (params) => request('/reagent/applyList', params, 'GET');
//删除试剂申请记录
export const apiReagentApplyDelete = (params) => request('/reagent/applyDelete', params, 'POST');
//获取试剂记录详情
export const apiReagentApplyDetail = (params) => request('/reagent/applyDetail', params, 'GET');
//修改试剂申请记录
export const apiReagentApplyUpdate = (params) => request('/reagent/applyUpdate', params, 'POST');
//获取试剂条件
export const apiReagentCondition = (params) => request('/reagent/condition', params, 'GET');
//导出试剂申请
export const apiReagentExport = (params) => request('/reagent/export', params, 'GET');

//获取故障处理记录
export const apiFaultRecordFaultList = (params) => request('/faultRecord/faultList', params, 'GET');
//删除故障处理记录
export const apiFaultRecordFaultDelete = (params) => request('/faultRecord/faultDelete', params, 'POST');
//获取故障处理记录详情
export const apiFaultRecordFaultDetail = (params) => request('/faultRecord/faultDetail', params, 'GET');

//项目建设列表
export const apiConstructionList = (params) => request('/Construction/list', params, 'GET');
//新建项目
export const apiConstructionAdd = (params) => request('/Construction/Add', params, 'POST');
//项目信息修改
export const apiConstructionUpdate = (params) => request('/Construction/update', params, 'POST');
//负责人员
export const apiConstructionStaff = (params) => request('/Construction/staff', params, 'GET');
//处理人员
export const apiConstructionHandleUser = (params) => request('/Construction/handleUser', params, 'GET');
//分配项目流程负责人
export const apiConstructionAllot = (params) => request('/Construction/allot', params, 'POST');
//删除项目
export const apiConstructionDelete = (params) => request('/Construction/delete', params, 'POST');
//项目进度基本信息
export const apiConstructionBasic = (params) => request('/Construction/basic', params, 'GET');
//项目进度具体详情
export const apiConstructionPlanDetail = (params) => request('/Construction/planDetail', params, 'GET');
//去处理
export const apiConstructionDispose = (params) => request('/Construction/dispose', params, 'POST');
//确认完成
export const apiConstructionFinish = (params) => request('/Construction/finish', params, 'POST');
//获取流程图片或文件
export const apiConstructionFile = (params) => request('/Construction/file', params, 'GET');
//图片上传
export const apiConstructionFileUpdate = (params) => reqUpload('/Construction/fileUpdate', params, 'POST');
//图片或文件删除
export const apiConstructionFileDelete = (params) => request('/Construction/fileDelete', params, 'POST');
//项目验收
export const apiConstructionProjectAcceptance = (params) => request('/Construction/projectAcceptance', params, 'POST');
//流程审核
export const apiConstructionAudit = (params) => request('/Construction/audit', params, 'POST');

//获取最新排口预警记录信息
export const apiWarnEarlyNewest = (params) => request('/warn/earlyNewest', params, 'GET');
//获取故障因子信息
export const apiWarnEarlyFactor = (params) => request('/warn/earlyFactor', params, 'GET');
//预警记录历史
export const apiWarnEarlyHistory = (params) => request('/warn/earlyHistory', params, 'GET');
//预警记录历史曲线
export const apiWarnEarlyLine = (params) => request('/warn/earlyLine', params, 'GET');

//获取确认记录信息
export const apiConfirmConfirmList = (params) => request('/confirm/confirmList', params, 'GET');
//确认记录导出
export const apiConfirmConfirmExport = (params) => request('/confirm/export', params, 'POST');

//获取处理记录信息
export const apiHandleHandleList = (params) => request('/handle/handleList', params, 'GET');
//处理记录预警详情
export const apiHandleHandleDetail = (params) => request('/handle/handleDetail', params, 'GET');

//获取可选区域
export const apiGisRegion = () => request('/gis/region', {}, 'GET');
//获取排口列表
export const apiGisOutletList = (params) => request('/gis/outletList', params, 'GET');

//获取系统操作日志信息
export const apiSetlogLogList = (params) => request('/Setlog/logList', params, 'GET');
//获取版本日志
export const apiVersionRecordList = (params) => request('/versionRecord/list', params, 'GET');
//添加版本日志
export const apiVersionRecordCreate = (params) => request('/versionRecord/create', params, 'POST');

//物联卡列表
export const apiSimCardList = (params) => request('/simCard/list', params, 'GET');
//物联卡详情
export const apiSimCardDetail = (params) => request('/simCard/detail', params, 'GET');
//物联卡添加
export const apiSimCardSimAdd = (params) => request('/simCard/simAdd', params, 'POST');
//物联卡更新
export const apiSimCardCardUpdate = (params) => request('/simCard/cardUpdate', params, 'POST');
//物联卡删除
export const apiSimCardDelete = (params) => request('/simCard/delete', params, 'POST');
//可添加物联卡排口
export const apiSimCardOutletList = (params) => request('/simCard/outletList', params, 'GET');

//更换记录列表
export const apiCardLogList = (params) => request('/cardLog/list', params, 'GET');

//备忘录列表
export const apiMemoList = (params) => request('/Memo/list', params, 'GET');
//评论列表
export const apiMemoReview = (params) => request('/Memo/review', params, 'GET');

//资料管理
//资料列表
export const apiDataManagementList = (params) => request('/dataManagement/list', params, 'GET');
//资料删除
export const apiDataManagementDelete = (params) => request('/dataManagement/delete', params, 'POST');
//资料添加
export const apiDataManagementAdd = (params) => request('/dataManagement/add', params, 'POST');
//资料上传
export const apiDataManagementDataAdd = (params) => reqUpload('/dataManagement/dataAdd', params, 'POST');
//资料详情
export const apiDataManagementDetail = (params) => request('/dataManagement/detail', params, 'GET');
//资料修改
export const apiDataManagementUpdate = (params) => request('/dataManagement/update', params, 'POST');
//资料分类列表
export const apiDataManagementClassifyList = (params) => request('/dataManagement/classifyList', params, 'GET');
//添加分类
export const apiDataManagementClassifyAdd = (params) => request('/dataManagement/classifyAdd', params, 'POST');
//修改分类
export const apiDataManagementClassifyUpdate = (params) => request('/dataManagement/classifyUpdate', params, 'POST');
//分类删除
export const apiDataManagementClassifyDelete = (params) => request('/dataManagement/classifyDelete', params, 'POST');

//运维评价
//排口数据
export const apiOperationsStatisticalEquip = (params) => request('/operationsStatistical/equip', params, 'GET');
//评价数据
export const apiOperationsStatisticalList = (params) => request('/operationsStatistical/list', params, 'GET');

// 表单筛选
export const apiFormRecordMonitor = (params) => request('/formRecord/monitor', params, 'GET');
export const apiFormRecordTypeList = (params) => request('/formRecord/typeList', params, 'GET');
export const apiFormRecordList = (params) => request('/formRecord/list', params, 'GET');
export const apiFormRecordDetail = (params) => request('/formRecord/detail', params, 'GET');
export const apiFormRecordPdf = (params) => request('/formRecord/pdf', params, 'GET');
