<template>
  <div class="app-logo">
    <router-link class="app-logo-route" to="/">
      <span class="app-logo-icon"></span>
      <span class="app-logo-text" v-if="!collapse">
        {{ config.appName }}
      </span>
    </router-link>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: mapState({
    config: (state) => state.config,
    collapse: (state) => state.collapse,
  }),
};
</script>
<style lang="less" scoped>
.app-logo {
  font-family: Futura, Helvetica Neue For Number, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 0px 19px;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  transition: padding 0.3s;
  .app-logo-route {
    text-decoration: none;
    .app-logo-text {
      font-size: 20px;
      vertical-align: middle;
      text-decoration: none;
      color: #ffffff;
    }
    .app-logo-icon {
      background: url(../assets/images/logo.png);
      background-size: contain;
      height: 26px;
      width: 26px;
      display: inline-block;
      transition: 0.3s;
      vertical-align: middle;
    }
  }
}
.h-layout-sider-collapsed {
  .app-logo-text {
    display: none;
  }
  .app-logo-icon {
    margin-left: 15px;
  }
}
</style>