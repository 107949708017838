import Vue from 'vue';
import md5 from 'js-md5';
import VueRouter from 'vue-router';
import store from "../store/index";
import config from "../config";
import routes from "./routes";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

/**
 *  检测客户是否登陆
 */
const checkIsLogin = () => {
  const session = Vue.localStorage.get(md5("session"));
  if (session) {
    return true;
  }
  else {
    return false;
  }
};

/**
 *  路由钩子
 */
router.beforeEach((to, from, next) => {
  // 设置meta
  const meta = to.meta;
  if (meta && meta.title) {
    document.title = meta.title;
  }
  // 面包屑
  if (meta && meta.breadcrumb) {
    store.commit("initBreadcrumb", meta.breadcrumb.split('/'));
  } else {
    store.commit("initBreadcrumb", []);
  }
  // 全局当前url
  store.commit("updateUri", to.path);
  // 校验登录，没登陆跳转登录界面
  if (to.path === config.loginRoute) {
    next();
  } else {
    if (checkIsLogin()) {
      next();
    } else {
      next({ path: config.loginRoute });
    }
  }
});

export default router
