<template>
  <div class="app-menu">
    <app-logo></app-logo>
    <el-menu :default-active="url" background-color="rgb(29, 37, 49)" text-color="#fff"
      active-text-color="#fff" :collapse="collapse" :collapse-transition="false"
      :unique-opened="true" @select="select" class="el-menu-vertical-demo"
    >
      <el-menu-item :index="config.homeRoute" :class="config.homeRoute === url ? 'active' : ''">
        <i class="el-icon-monitor" style="color:#fff;"></i>
        <span slot="title">控制面板</span>
      </el-menu-item>
      <el-submenu v-for="(nav, i) in navigation" :key="i" :index="nav.url">
        <template slot="title">
          <i :class="nav.icon" style="color:#fff;"></i>
          <span>{{ nav.name }}</span>
        </template>
        <el-menu-item v-for="(child, a) in nav.children" :key="a" :index="child.url" :class="child.url === url ? 'active' : ''">
          {{ child.name }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
import appLogo from "components/app-logo.vue";

export default {
  components: {
    appLogo,
  },
  data() {
    return {
      menus: [],
    };
  },
  watch: {
    $route() {},
  },
  computed: mapState({
    collapse: (state) => state.collapse,
    config: (state) => state.config,
    navigation: (state) => state.menus,
    url: (state) => state.url,
  }),
  methods: {
    select(route) {
      const _this = this;
      if (_this.url !== route) {
        _this.$router.push({
          path: route
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app-menu {
  user-select: none;
}
.active {
  background-color: #2f69eb !important;
}
/deep/ .el-submenu__title:hover{
  background-color: #2f69eb !important;
}
/deep/ .el-menu-item:hover{
  background-color: #2f69eb !important;
}
/deep/ .el-submenu__title i {
  color: #fff;
}
</style>